import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import BBFCHeaderV2 from './redesign24/BBFCHeaderV2'

const getCtaLink = (navigationItem) => {
  if (navigationItem.type === 'section') {
    return navigationItem.section?.fullUrlPath || ''
  } else if (['internalLink', 'externalLink'].includes(navigationItem.type)) {
    return navigationItem.url
  } else {
    return ''
  }
}

const buildNavigationItemTree = (navigation) => {
  if (!navigation) return []
  return navigation
    .filter((item) => item.parent === null)
    .map((item) => ({
      name: item.title,
      items: navigation
        .filter(
          (subItem) =>
            subItem.parent === item.id && subItem.displayType !== 'featuredCard'
        )
        .map((subItem) => [
          {
            ctaLabel: subItem.title,
            ctaLink: getCtaLink(subItem),
            type: subItem.type,
            displayType: subItem.displayType
          },
          ...navigation
            .filter((subSubItem) => subSubItem.parent === subItem.id)
            .map((subSubItem) => ({
              ctaLabel: subSubItem.title,
              ctaLink: getCtaLink(subSubItem),
              type: subSubItem.type,
              displayType: subSubItem.displayType
            }))
        ]),
      featuredItems: navigation
        .filter(
          (subItem) =>
            subItem.parent === item.id && subItem.displayType === 'featuredCard'
        )
        .map((subItem) => ({
          title: subItem.title,
          ctaLabel: subItem.linkText,
          ctaLink: getCtaLink(subItem),
          bgImage: subItem.images.thumbnail_3x2_438[0].url
        }))
    }))
}

const Header = (props) => {
  useEffect(() => {})

  const navigationItems = buildNavigationItemTree(props.instance.navigation)

  return (
    <>
      <BBFCHeaderV2 navigationItems={navigationItems} />
    </>
  )
}

Header.propTypes = {
  // showDecorationBanner: PropTypes.bool,
  instance: PropTypes.shape({
    navigation: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        displayType: PropTypes.string,
        type: PropTypes.string,
        section: PropTypes.shape({
          fullUrlPath: PropTypes.string
        }),
        url: PropTypes.string,
        linkText: PropTypes.string,
        parent: PropTypes.string,
        order: PropTypes.number
      })
    )
  })
}

export default Header
